<!--
 * @Description: 
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-04 11:16:37
 * @LastEditors: PSG
 * @LastEditTime: 2021-04-09 16:52:12
-->
<template>
  <div class="account-login">
    <div class="wrapper">
      <div class="logo-wrapper">
        <span class="text">产品LOGO</span>
      </div>
      <div class="text-wrapper">
        <div class="text">账户密码登录</div>
        <div class="line"></div>
      </div>

      <a-form 
        class="form-wrapper"
        :model="account_form"
        :rules="rules"
        @finish="handleFinish">
        <a-form-item name="username" has-feedback>
          <a-input v-model:value="account_form.username" type="text" autocomplete="off" class="ant-input" placeholder="用户名" style="width:19.19rem;padding:0 32px 0 10px">
            <template #prefix><UserOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
          </a-input>
        </a-form-item>
        <a-form-item name="password" has-feedback>
          <a-input v-model:value="account_form.password" type="password" autocomplete="off" class="password" placeholder="密码" style="width:19.19rem;margin-top:20px;padding:0 32px 0 10px">
            <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button class="submit" html-type="submit"> 登录</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getLogin } from '@/api/account'
import { message } from 'ant-design-vue';
import { validatePhone, validatePassword } from '@/utils/validate'
import { reactive, toRefs, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { UserOutlined, InfoCircleOutlined, LockOutlined } from '@ant-design/icons-vue';


/**
 * 处理表单校验逻辑
 */
const formValidateEffect = () => {
  // 前端账户校验
  let checkUsername = async (rule, value, calback) => {
    return Promise.resolve()
    // if (!value) {
    //   return Promise.reject('账户不能为空');
    // }
    // if (!validatePhone(value)) {
    //   return Promise.reject('请输入11位数字的手机号')
    // } else {
    //   return Promise.resolve()
    // }
  }
  // 前端密码校验
  let checkPassword = async (rule, value, calback) => {
    return Promise.resolve()
    // if (!value) {
    //   return Promise.reject('密码不能为空');
    // }
    // if (!validatePassword(value)) {
    //   return Promise.reject('至少6位包含数字和字母，最多32位')
    // } else {
    //   return Promise.resolve()
    // }
  }
  const formConfig = reactive({
    account_form: {
      username: '',
      password: ''
    },
    rules: {
      username: [{ validator: checkUsername, trigger: 'blur' }],
      password: [{ validator: checkPassword, trigger: 'blur'}]
    }
  })

  return { formConfig }
}

/**
 * 处理登陆逻辑
 */
const　userLoginEffect = (data, router) => {
  // 提交表单
  const handleFinish = async() => {
    const formData = {
      username: data.account_form.value.username,
      password: data.account_form.value.password
    }
    const result = await getLogin(formData)
    // const result = await axios.get('/open/login/password', formData, {})
    if (result.status === 200 && result.data?.code === '00000') {
      const resData = result.data
      message.success('登陆成功')
      router.push('/personal')
    } else {
      message.warn('账号或密码错误，请重新输入')
    }
  }
  return { handleFinish }
}

/**
 * 程序入口
 */
export default {
  name: 'Login',
  components: {
    UserOutlined,
    InfoCircleOutlined,
    LockOutlined
  },
  setup() {
    // 职责：代码执行的流程
    const { formConfig } = formValidateEffect()
    const data = toRefs(formConfig)
    const router = useRouter()
    const { handleFinish } = userLoginEffect(data, router)
    
    return {
      ...data,
      handleFinish
    }
  }
}
</script>

<style lang="less" scoped>
  .account-login {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/login-bg.jpg');
    background-size: 100%;
    .wrapper {
      padding-top: 6.57rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo-wrapper {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 12.5rem;
        height: 4.38rem;
        background-color: #fff;
      }
      .text-wrapper {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        width: 19.19rem;
        .text {
          text-align: center;
          color: #F59A23;
          padding-bottom: .75rem;
        }
        .line {
          height: 1px;
          width: 50%;
          border-top: solid #F59A23 1px;
          margin: 0 auto;
        }
      }
      .form-wrapper {
        width: 19.19rem;
        button,input {
          width: 17.19rem;
          height: 1.86rem;
          // border: red;
        }
        .submit {
          width: 19.19rem;
          margin-top: 20px;
          height: 36px;
          color: #fff;
          background-color: #F59A23;
        }
      }
    }
  }
</style>
